(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.matches.controller:MatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.invoicestrimestral')
    .controller('invoicestrimestral', invoicestrimestral);

  function invoicestrimestral(AclService,Invoicesgrouptrimestral,townHalls, $mdToast, $filter, $scope, $state,excel, $timeout, $mdDialog) {
    var vm = this;
     var organisationList = angular.copy(townHalls);
    vm.isAdm=AclService.isAdmin();
    vm.applyFilters = applyFilters;
    var indexPersones=[];
    vm.llistatpersones=[];
    vm.llistatpersones1=[];
     var llistatpersones2=[];
     var llistatpersones3=[];
     var llistatpersones4=[];
    
      vm.matches = [];
    vm.selected = [];
    vm.select = undefined;
    vm.dis=true;
    var template;
        
     
    function applyFilters() {
      vm.isFiltered = true;
      
      var a= moment(vm.iniDate).format('YYYY');
      query1( "01/01/"+a, "12/31/"+a);
     
    }
    
 
    
     function query1( iniDate, endDate) {
          var parameters = {
                initDate: moment(iniDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                isAdmin:AclService.isAdmin(),
                idAjuntament:townHalls[0].id
      };
     
     vm.llistatpersones=Invoicesgrouptrimestral.query(parameters);
   
     }
     
    
     
      
     
     
      $scope.exportToExcel=function(tableId){ // ex: '#my-table'
            var exportHref=excel.tableToExcel(tableId,'name');
            $timeout(function(){location.href=exportHref;},100); // trigger download
        };
    vm.imprimir=function(){
        var trato="";
        var c=0;
        
         angular.forEach(vm.llistatpersones, function (persona) {
          if (persona.selected) {
           trato=trato+"-"+persona.numinvoice+"/"+persona.idreferee;
            c++;
          
                 vm.persona=persona;
            // $scope.$watch(persona, function(e) { vm.persona = persona; });
            
             var url =    $state.href('home.invoiceref', {personId: persona.idreferee,numinvoice:persona.numinvoice},{reload:true});
            // var printWin = window.open(url, '');
   //   printWin.document.write(s);
      
  //  printWin.document.write(template);
  //  printWin.document.close();
            var p=    window.open(url,'');
            
            
                 template = document.getElementById('invoicePrintPreview').innerHTML;
                 
   
       
            
 // var printWin = window.open('', '');
   //   printWin.document.write(s);
      
  //  printWin.document.write(template);
  //  printWin.document.close();
      //printWin.onload = function () {
      
          }
          
        });
        if (c===0) {alert("Si us plau, selecciona al menys una factura per imprimir");vm.dis=true;}
      
    };
    vm.enable=function(){
         vm.dis=false;
    };
  }
}());





